.nk__impressum {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);
    background: radial-gradient(circle at 3% 25%, rgba(33, 211, 117, 1) 0%, rgba(7, 59, 58, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.nk__gradient__text {
    background: white;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}  

.nk__impressum-heading {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: left;

    margin: 4rem 0 2rem;
}

.nk__impressum-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.nk__impressum-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}





@media screen and (max-width: 850px) {
    .nk__impressum-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .nk__impressum-heading p {
        margin-top: 1rem;

    }


}

@media screen and (max-width: 650px) {
    .nk__impressum-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .nk__impressum-heading p {
        font-size: 12px;
        line-height: 20px;
    }
}



