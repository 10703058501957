/*@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  /*--font-family: 'Manrope', sans-serif;*/
  --font-family: 'Poppins', sans-serif;


  --gradient-text: linear-gradient(89.97deg, #21D375 1.84%, #08A045 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #073B3A -13.86%, #21D375 99.55%);
  
  --color-bg: #073B3A;
  --color-footer : linear-gradient(103.22deg, #21D375 -13.86%, #073B3A 99.55%);

  --color-blog: #042c54;
  /*--color-text: #FFFFFF;*/
  --color-text-nbg: #08A045;
  --color-text: #FFFFFF;
  --color-header: #6BBF59;
  --color-subtext: #042524;
}