.nk__datenschutz {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);
    background: radial-gradient(circle at 3% 25%, rgba(33, 211, 117, 1) 100%, rgba(7, 59, 58, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}


.nk__datenschutz {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;

    margin: 4rem 0 2rem;
}

.nk__datenschutz h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);
    
}

.nk__datenschutz p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-subtext);
    cursor: cursor;
}