@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

.nk__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.nk__brand div {
    flex: 1 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.nk__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar);
}

.nk__cta-content p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: var(--color-text);
}

.nk__cta-content h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
    line-height: 45px;
    color: var(--color-subtext);
}

.nk__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.nk__cta-btn button {
    background: #000000;
    color: #FFFFFF;
    font-family: var(--font-family);
    border-radius: 40px;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .nk__cta {
        flex-direction: column;
    }

    .nk__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .nk__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .nk__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .nk__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}
.nk__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1 rem;
}

.nk__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}

.nk__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04rem;
    color: #ffff;
}

.nk__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    margin-bottom: 0.25rem;
}

.nk__features-container_feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.nk__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .nk__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .nk__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .nk__features-container__feature {
        margin: 1rem 0;
    }
}
.nk__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.nk__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.nk__navbar-links_logo {
    margin-right: 2rem;
}

.nk__navbar-links_logo img {
    width: 289px;
    height: 93.27px;
}

.nk__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.nk__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nk__navbar-links_container p,
.nk__navbar-sign p,
.nk__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.nk__navbar-sign button,
.nk__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #0B6E4F;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 2rem;
}

.nk__navbar_menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.nk__navbar_menu svg {
    cursor: pointer;
}

.nk__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 4.5rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.nk__navbar-menu_container p {
    margin: 1rem 0;
}

.nk__navbar-menu_container-links {
    display: none;
}

@media screen and (max-width: 1050px) {
    .nk__navbar-links_container {
        display: none;
    }
    .nk__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .nk__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .nk__navbar {
        padding: 2rem
    }

    .nk__navbar-sign {
        display: none
    }

    .nk__navbar-menu_container {
        top: 20px;
    }

    .nk__navbar-menu_container-links-sign {
        display: block;
    }
}
.nk__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.nk__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.nk__navbar-links_logo {
    margin-right: 2rem;
}

.nk__navbar-links_logo img {
    width: 289px;
    height: 93.27px;
}

.nk__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.nk__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nk__navbar-links_container p,
.nk__navbar-sign p,
.nk__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.nk__navbar-sign button,
.nk__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #0B6E4F;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 2rem;
}

.nk__navbar_menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.nk__navbar_menu svg {
    cursor: pointer;
}

.nk__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 4.5rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.nk__navbar-menu_container p {
    margin: 1rem 0;
}

.nk__navbar-menu_container-links {
    display: none;
}

@media screen and (max-width: 1050px) {
    .nk__navbar-links_container {
        display: none;
    }
    .nk__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .nk__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .nk__navbar {
        padding: 2rem
    }

    .nk__navbar-sign {
        display: none
    }

    .nk__navbar-menu_container {
        top: 20px;
    }

    .nk__navbar-menu_container-links-sign {
        display: block;
    }
}

.nk__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.nk__features-heading {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-left: 2rem;
}

.nk__features-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin-left: 2rem;
}

.nk__features-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16;
    line-height: 30px;
    color: var( --color-text-nbg);
    margin-top: 2rem;
    margin-left: 2rem;
}

.nk__features-container {
    flex: 1.5 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 5rem;
}


@media screen and (max-width: 990px) {
    .nk__features {
        flex-direction: column;
    }

    .nk__features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .nk__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
  
}
.nk__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}


.nk__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.nk__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04rem;
}

.nk__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;
    margin-bottom: 10rem;
    cursor: pointer;
}

.nk__footer-btn p {
    box-sizing: border-box;
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    word-spacing: 2px;
}

.nk__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;

    width: 100%;
    text-align: left;
}

.nk__footer-links div {
    width: 250px;
    margin: 1rem;
}

.nk__footer-logo {
    display: flex;
    flex-direction: column;
}

.nk__footer-links-logo img {
    width: 289px;
    height: 93.27px;

    margin-bottom: 1rem;
}

.nk__footer-links-logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
}

.nk__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.nk__footer-links_div h4 {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;

    margin-bottom: 1rem;
}

.nk__footer-links_div h5 {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    cursor: pointer;

    margin-bottom: 1rem;
}

.nk__footer-links_div p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;

    margin: 0.5rem 0;
    cursor: pointer;
}

.nk__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.nk__footer-copyright p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
}

@media screen and (max-width: 850px) {
    .nk__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .nk__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .nk__footer-links div {
        margin: 1rem 0;
    }

    .nk__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .nk__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}
.nk__header {
    display: flex;
}

.nk__header-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    
    margin-right: 5rem;
}

.nk__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800px;
    font-size: 72px;
    line-height: 75px;
    letter-spacing: -0.04em;
    color: var(--color-header);
    margin: 2rem;

}

.nk__header-content p {
    font-family: var(--font-family);
    font-weight: 800px;
    font-size: 36px;
    line-height: 35px;
    letter-spacing: 0.01em;
    color: var(--color-text);
    margin: 2rem;
}

.nk__header-qrcode {
    width: 60%;
    margin: 2rem 2rem 1rem;

    display: flex;
}

.nk__header-qrcode img {
    width: 60%;
    height: auto;
}

.nk__header-qrcode button {
    flex: 0.6 1;
    width: 100%;
    min-height: 50px;
    max-height: 100px;

    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    background: #6BBF59;
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 2 2 1 2rem;
    border: none;

    margin-right: 5rem;
    margin-left: 0rem;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    
    


}



.nk__header-image {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nk__header-image img {
    width: 50%;
    height: auto;
}

@media screen and (max-width: 1050px) {
    .nk__header{
        flex-direction: column;
    }

    .nk__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 950px) {
    .nk__header-image img {
        width: 50%;
        height: auto;
    }
}

@media screen and (max-width: 650px) {

    .nk__header h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .nk__header p {
        font: size 16px;
        line-height: 35px;
    }

    .nk__header-content__input button,
    .nk__header-content__input input {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .nk__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .nk__header p {
        font-size: 14px;
        line-height: 24px;
    }

    .nk__header-content__input button,
    .nk__header-content__input input {
        font-size: 12px;
        line-height: 16px;
    }
}

.nk__possibility {
    display: flex;
    flex-direction: row;
} 

.nk__possibility-image {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.nk__possibility-image img {
    width: 50%;
    height: auto;
}

.nk__possibility-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.nk__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-text-nbg);
}
.nk__possibility-content h4:last-child{
    color: var(--color-text-nbg);
}
.nk__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;

    margin: 1rem 0;
}

.nk__possibility-content p {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    color: var(--color-text);
    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .nk__possibility {
        flex-direction: column;
    }

    .nk__possibility-image {
        margin: 1rem 0;
    }

    .nk__possibility-image img {
        width: 75%;
        height: auto;
    }

    .nk__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .nk__possibility-image img {
        width: 100%;
        height: 100%;
    }
}
.nk__what {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);
    background: radial-gradient(circle at 3% 25%, rgba(7, 59, 58, 1) 0%, rgba(33, 211, 117, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.nk__what-feature {
    display: flex;
}

.nk__what-feature .nk__features-container_feature {
    margin: 0;
}

.nk__what-feature .nk__features-container_feature-text {
    max-width: 700px;
}

.nk__what-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 4rem 0 2rem;
}

.nk__what-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.nk__what-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.nk__what-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.nk__what-container .nk__features-container__feature {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}
/* Customizing feature component as per needs */
.nk__what-container .nk__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .nk__what-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .nk__what-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .nk__what-feature .nk__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .nk__what-feature .nk__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .nk__what-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .nk__what-container .nk__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}

.nk__agb {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);
    background: radial-gradient(circle at 3% 25%, rgba(33, 211, 117, 1) 100%, rgba(7, 59, 58, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}


.nk__agb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;

    margin: 4rem 0 2rem;
}

.nk__agb h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);
    
}

.nk__agb p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-subtext);
    cursor: cursor;
}
.nk__impressum {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);
    background: radial-gradient(circle at 3% 25%, rgba(33, 211, 117, 1) 0%, rgba(7, 59, 58, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.nk__gradient__text {
    background: white;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}  

.nk__impressum-heading {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: left;

    margin: 4rem 0 2rem;
}

.nk__impressum-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.nk__impressum-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}





@media screen and (max-width: 850px) {
    .nk__impressum-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .nk__impressum-heading p {
        margin-top: 1rem;

    }


}

@media screen and (max-width: 650px) {
    .nk__impressum-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .nk__impressum-heading p {
        font-size: 12px;
        line-height: 20px;
    }
}




.nk__datenschutz {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);
    background: radial-gradient(circle at 3% 25%, rgba(33, 211, 117, 1) 100%, rgba(7, 59, 58, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}


.nk__datenschutz {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;

    margin: 4rem 0 2rem;
}

.nk__datenschutz h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);
    
}

.nk__datenschutz p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-subtext);
    cursor: cursor;
}
/*gradient: https://angrytools.com/gradient/*/
/*button animation: https://animista.net/play/basic/scale-up*/


* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg)
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {
    /*background: radial-gradient(circle at 3% 25%, rgba(33, 211, 117, 1) 0%, rgba(7, 59, 58, 1) 100%);*/
    background: radial-gradient(circle at 3% 25%, rgba(7, 59, 58, 1) 0%, rgba(33, 211, 117, 1) 100%);
}
        
.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}     

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes scale-up-center {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
}
  
@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }
    .section__margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550) {
    .section__padding {
        padding: 4rem 2rem;
    }
    .section__margin {
        margin: 4rem 2rem;
    }
}
/*@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');*/

:root {
  /*--font-family: 'Manrope', sans-serif;*/
  --font-family: 'Poppins', sans-serif;


  --gradient-text: linear-gradient(89.97deg, #21D375 1.84%, #08A045 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #073B3A -13.86%, #21D375 99.55%);
  
  --color-bg: #073B3A;
  --color-footer : linear-gradient(103.22deg, #21D375 -13.86%, #073B3A 99.55%);

  --color-blog: #042c54;
  /*--color-text: #FFFFFF;*/
  --color-text-nbg: #08A045;
  --color-text: #FFFFFF;
  --color-header: #6BBF59;
  --color-subtext: #042524;
}
