.nk__header {
    display: flex;
}

.nk__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    
    margin-right: 5rem;
}

.nk__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800px;
    font-size: 72px;
    line-height: 75px;
    letter-spacing: -0.04em;
    color: var(--color-header);
    margin: 2rem;

}

.nk__header-content p {
    font-family: var(--font-family);
    font-weight: 800px;
    font-size: 36px;
    line-height: 35px;
    letter-spacing: 0.01em;
    color: var(--color-text);
    margin: 2rem;
}

.nk__header-qrcode {
    width: 60%;
    margin: 2rem 2rem 1rem;

    display: flex;
}

.nk__header-qrcode img {
    width: 60%;
    height: auto;
}

.nk__header-qrcode button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    max-height: 100px;

    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    background: #6BBF59;
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 2 2 1 2rem;
    border: none;

    margin-right: 5rem;
    margin-left: 0rem;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    
    


}



.nk__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nk__header-image img {
    width: 50%;
    height: auto;
}

@media screen and (max-width: 1050px) {
    .nk__header{
        flex-direction: column;
    }

    .nk__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 950px) {
    .nk__header-image img {
        width: 50%;
        height: auto;
    }
}

@media screen and (max-width: 650px) {

    .nk__header h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .nk__header p {
        font: size 16px;
        line-height: 35px;
    }

    .nk__header-content__input button,
    .nk__header-content__input input {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .nk__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .nk__header p {
        font-size: 14px;
        line-height: 24px;
    }

    .nk__header-content__input button,
    .nk__header-content__input input {
        font-size: 12px;
        line-height: 16px;
    }
}
